import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var useFolderPermissions = function (user) {
    var hasPermissionCreateFolder = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.FOLDER, MethodCode.CREATE);
    var hasPermissionEditFolder = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.FOLDER, MethodCode.EDIT);
    var hasPermissionDeleteFolder = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.FOLDER, MethodCode.DELETE);
    var hasPermissionListFolder = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.FOLDER, MethodCode.LIST);
    var hasPermissionViewFolder = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.FOLDER, MethodCode.VIEW);
    var hasPermissionUploadFile = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.FILE_UPLOAD, MethodCode.CREATE);
    return {
        hasPermissionEditFolder: hasPermissionEditFolder,
        hasPermissionCreateFolder: hasPermissionCreateFolder,
        hasPermissionDeleteFolder: hasPermissionDeleteFolder,
        hasPermissionListFolder: hasPermissionListFolder,
        hasPermissionViewFolder: hasPermissionViewFolder,
        hasPermissionUploadFile: hasPermissionUploadFile
    };
};
export default useFolderPermissions;
